<template>
  <v-app>
    <default-core-drawer :expand-on-hover.sync="expandOnHover" />
    <NotificationContainer />
    <UpdateApp />
    <default-core-view />
    <dashboard-core-footer />
  </v-app>
</template>

<script>
export default {
  name: 'LayoutDefault',
  components: {
    NotificationContainer: () =>
      import('@/components/core/NotificationContainer'),
    DefaultCoreDrawer: () => import('@/components/core/Drawer'),
    DefaultCoreView: () => import('@/components/core/View'),
    UpdateApp: () => import('@/components/core/UpdateApp'),
    DashboardCoreFooter: () => import('@/components/core/Footer'),
  },
  data: () => ({
    expandOnHover: false,
  }),
};
</script>
